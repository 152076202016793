var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c("div", { staticClass: "top-box" }, [
      _c(
        "div",
        {
          staticClass: "logo-box",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.jumpInitialPage($event)
            },
          },
        },
        [_c("el-image", { attrs: { fit: "cover", src: "/img/logo.png" } })],
        1
      ),
    ]),
    _c("div", { staticClass: "login-weaper" }, [
      _c("div", { staticClass: "right-box" }, [
        _c("div", { staticClass: "login-title" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.type
                  ? _vm.$t("login.doctorLogin")
                  : _vm.$t("login.findPassword")
              ) +
              "\n      "
          ),
        ]),
        _c(
          "div",
          { staticClass: "login-main" },
          [_c("userLogin", { on: { changeType: _vm.changeType } })],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "login-page-footer" }, [
      _c("div", { staticClass: "foot-box" }, [
        _c("span", [
          _vm._v(
            _vm._s("ICP" + _vm.$t("login.putRecordsNum") + "：") + "\n        "
          ),
          _c(
            "a",
            { attrs: { href: "https://beian.miit.gov.cn", target: "_blank" } },
            [_vm._v(_vm._s(_vm.$t("login.currentRecord")))]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }