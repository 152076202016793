var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error500" }, [
    _c(
      "div",
      { staticClass: "error500-body-con" },
      [
        _c("el-card", { staticClass: "box-card" }, [
          _c("div", { staticClass: "error500-body-con-title" }, [
            _vm._v("\n        5\n        "),
            _c("span", { staticClass: "error500-0-span" }, [
              _c("i", { staticClass: "icon-debug" }),
            ]),
            _c("span", { staticClass: "error500-0-span" }, [
              _c("i", { staticClass: "icon-debug" }),
            ]),
          ]),
          _c("p", { staticClass: "error500-body-con-message" }, [
            _vm._v("Oops! the server is wrong"),
          ]),
          _c(
            "div",
            { staticClass: "error500-btn-con" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "200px" },
                  attrs: { size: "large", type: "text" },
                  on: { click: _vm.goHome },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("common.goBack") + _vm.$t("home.homePage"))
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "200px", "margin-left": "40px" },
                  attrs: { size: "large", type: "primary" },
                  on: { click: _vm.backPage },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("common.goBack") + _vm.$t("common.prevPage"))
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }