<template>
  <div class="drug-use-assistant animate__animated animate__fadeOutRight">
    <div class="assistant-title">
      <el-image src="/img/yy_logo.png"
                fit="cover"
                style="margin: 0 10px;" />
      <span>银叶合理用药助手</span>
    </div>
    <div class="assistant-content ">
      <el-tabs v-model="activeName">
        <el-tab-pane label="用药检测"
                     name="first">
          <drugTest v-show="checkSuccess"
                    ref="drugTestRef" />
          <testFail v-show="!checkSuccess"
                    ref="testFailRef"
                    @checkDrugs="checkDrugs" />
        </el-tab-pane>
        <el-tab-pane label="处方用药"
                     style="height:100%"
                     name="second">
          <drugInfo ref="drugInfo" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import drugTest from './drugTest.vue'
import testFail from './testFail.vue'
import drugInfo from './drugInfo.vue'
import socketPublic from '@/socketMsg/socketPublic.js'
export default {
  name: 'DrugUseAssistant',
  components: {
    drugTest,
    drugInfo,
    testFail
  },
  data () {
    return {
      activeName: 'first',
      isFirst: false
    }
  },
  computed: {
    checkSuccess () {
      return socketPublic.state.checkSuccess
    }
  },
  methods: {
    checkDrugs () {
      if (this.$resf.drugTestRef) this.$resf.drugTestRef.checkDrugs(true)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-badge__content {
  top: 10px;
  right: 6px;
}
.drug-use-assistant {
  width: 400px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #fff;
  .assistant-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    margin-top: 20px;
    border-bottom: 1px solid #d8d8d8;
    padding: 0 10px;
    padding-bottom: 5px;
    height: 30px;
    /deep/ .el-image {
      width: 24px;
    }
  }
  .assistant-content {
    height: calc(100% - 60px);
    padding: 0 10px;
    /deep/ .el-tabs {
      height: 100%;
      .el-tabs__content {
        height: calc(100% - 40px);
        overflow-y: auto;
      }
    }
    /deep/ .el-tabs__header {
      margin-bottom: 6px;
    }
    /deep/ .el-tabs__item {
      color: #333;
      font-weight: 600;
    }
    /deep/ .el-tabs__item.is-active {
      color: #2d85ee;
    }
    /deep/ .el-tabs__active-bar {
      background-color: #2d85ee;
    }
    /deep/ .el-tabs__nav-wrap::after {
      background: #fff;
    }
  }
}
</style>
