var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "dragBox", staticClass: "drag" }, [
    _c("div", { ref: "dragBackground", staticClass: "drag-background" }),
    _c("div", { ref: "dragText", staticClass: "drag-text" }, [
      _vm._v(_vm._s(_vm.confirmWords)),
    ]),
    _c("div", {
      ref: "moveBox",
      staticClass: "handler handler-background",
      class: { "success-background": _vm.checkSuccess },
      staticStyle: { position: "absolute", top: "0px", left: "0px" },
      on: {
        mousedown: function ($event) {
          return _vm.mousedownFn($event)
        },
        touchstart: function ($event) {
          return _vm.mousedownFn($event)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }