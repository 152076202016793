var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("login.faceCheck"),
        visible: _vm.isVisible,
        "close-on-click-modal": false,
        top: "6vh",
        width: "630px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
        close: function ($event) {
          _vm.isVisible = false
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "text-align": "center", "font-size": "16px" } },
        [_vm._v("请使用微信扫码")]
      ),
      _c("el-image", { attrs: { src: _vm.imageUrl, fit: "cover" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }