var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-box" },
    [
      _vm.loginType === "password" || _vm.loginType === "note"
        ? _c(
            "div",
            { staticClass: "login-tab", on: { click: _vm.changeLogin } },
            [
              _c(
                "div",
                {
                  staticClass: "tab-item",
                  class: _vm.loginType === "password" ? "is-active" : "",
                  attrs: { "data-type": "password" },
                },
                [_vm._v(_vm._s(_vm.$t("login.passwordLogin")))]
              ),
              _c("div", { staticClass: "tab-line" }),
              _c(
                "div",
                {
                  staticClass: "tab-item",
                  class: _vm.loginType === "note" ? "is-active" : "",
                  attrs: { "data-type": "note" },
                },
                [_vm._v(_vm._s(_vm.$t("login.noteLogin")))]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            "status-icon": false,
            rules: _vm.loginRules,
            model: _vm.loginForm,
            "label-width": "0",
          },
        },
        [
          _vm.loginType === "password"
            ? [
                _c(
                  "el-form-item",
                  { key: "username", attrs: { prop: "username" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          placeholder: _vm.$t("login.loginAccount"),
                          size: "small",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.username,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.loginForm,
                              "username",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "loginForm.username",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            slot: "prefix",
                            src: "/img/doctor_user.png",
                          },
                          slot: "prefix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { key: "password", attrs: { prop: "password" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          type: _vm.passwordType,
                          placeholder: _vm.$t("login.password"),
                          size: "small",
                          "auto-complete": "new-password",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.loginForm,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "loginForm.password",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            slot: "prefix",
                            src: "/img/login_password.png",
                          },
                          slot: "prefix",
                        }),
                        _c("img", {
                          staticClass: "suffix-icon-password",
                          attrs: {
                            slot: "suffix",
                            src:
                              _vm.passwordType === "password"
                                ? "/svg/eye-open.svg"
                                : "/svg/eye.svg",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.showPassword("passwordType")
                            },
                          },
                          slot: "suffix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.loginType === "note"
            ? [
                _c(
                  "el-form-item",
                  { key: "phone", attrs: { prop: "phone" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          placeholder: _vm.$t("login.phone"),
                          size: "small",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.phone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.loginForm,
                              "phone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "loginForm.phone",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            slot: "prefix",
                            src: "/img/doctor_user.png",
                          },
                          slot: "prefix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { key: "code", attrs: { prop: "code" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          placeholder: _vm.$t("login.code"),
                          size: "small",
                          maxlength: "6",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.code,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.loginForm,
                              "code",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "loginForm.code",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "icon-yanzhengma",
                          attrs: { slot: "prefix" },
                          slot: "prefix",
                        }),
                        _c("template", { slot: "suffix" }, [
                          _c("div", { staticClass: "suffix-text" }, [
                            _c("div", {
                              staticClass: "suffix-line",
                              class: [{ "display-line": _vm.isGetCode }],
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "msg-text",
                                class: [{ display: _vm.isGetCode }],
                                on: { click: _vm.handleCheck },
                              },
                              [_vm._v(_vm._s(_vm.msgText))]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.loginType === "findPass"
            ? [
                _c(
                  "el-form-item",
                  { key: "findPhone", attrs: { prop: "findPhone" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          placeholder: _vm.$t("login.phone"),
                          size: "small",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.findPhone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.loginForm,
                              "findPhone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "loginForm.findPhone",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            slot: "prefix",
                            src: "/img/doctor_user.png",
                          },
                          slot: "prefix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { key: "findCode", attrs: { prop: "findCode" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          placeholder: _vm.$t("login.code"),
                          size: "small",
                          maxlength: "6",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.findCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.loginForm,
                              "findCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "loginForm.findCode",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "icon-yanzhengma",
                          attrs: { slot: "prefix" },
                          slot: "prefix",
                        }),
                        _c("template", { slot: "suffix" }, [
                          _c("div", { staticClass: "suffix-text" }, [
                            _c("div", {
                              staticClass: "suffix-line",
                              class: [{ "display-line": _vm.isGetCode }],
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "msg-text",
                                class: [{ display: _vm.isGetCode }],
                                on: { click: _vm.handleCheck },
                              },
                              [_vm._v(_vm._s(_vm.msgText))]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.loginType === "changePass"
            ? [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      "popper-options": {
                        boundariesElement: "viewport",
                        removeOnDestroy: true,
                      },
                      effect: "dark",
                      trigger: "click",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v("1、" + _vm._s(_vm.$t("login.passageValid3"))),
                      ]),
                      _c("p", [
                        _vm._v("2、" + _vm._s(_vm.$t("login.passageValid4"))),
                      ]),
                      _c("p", [
                        _vm._v("3、" + _vm._s(_vm.$t("login.passageValid5"))),
                      ]),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        key: "password1",
                        attrs: { slot: "reference", prop: "password1" },
                        slot: "reference",
                      },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              type: _vm.passwordType1,
                              placeholder:
                                _vm.$t("common.pleaseInput") +
                                _vm.$t("login.newPassword"),
                              size: "small",
                              "auto-complete": "new-password",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleLogin($event)
                              },
                            },
                            model: {
                              value: _vm.loginForm.password1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.loginForm,
                                  "password1",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "loginForm.password1",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                slot: "prefix",
                                src: "/img/change_password.png",
                              },
                              slot: "prefix",
                            }),
                            _c("img", {
                              staticClass: "suffix-icon-password",
                              attrs: {
                                slot: "suffix",
                                src:
                                  _vm.passwordType1 === "password"
                                    ? "/svg/eye-open.svg"
                                    : "/svg/eye.svg",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showPassword("passwordType1")
                                },
                              },
                              slot: "suffix",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { key: "password2", attrs: { prop: "password2" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          type: _vm.passwordType2,
                          placeholder:
                            _vm.$t("login.inputPassword") +
                            _vm.$t("login.newPassword"),
                          size: "small",
                          "auto-complete": "new-password",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.password2,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.loginForm,
                              "password2",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "loginForm.password2",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            slot: "prefix",
                            src: "/img/change_password.png",
                          },
                          slot: "prefix",
                        }),
                        _c("img", {
                          staticClass: "suffix-icon-password",
                          attrs: {
                            slot: "suffix",
                            src:
                              _vm.passwordType2 === "password"
                                ? "/svg/eye-open.svg"
                                : "/svg/eye.svg",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.showPassword("passwordType2")
                            },
                          },
                          slot: "suffix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("VerifySlider", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              (_vm.loginType === "findPass" || _vm.loginType === "note") &&
              (_vm.hadClick || _vm.isGetCode),
            expression:
              "(loginType==='findPass'|| loginType==='note')&&(hadClick||isGetCode)",
          },
        ],
        ref: "VerifySlider",
        on: { checkSuccess: _vm.checkSuccess },
      }),
      _c(
        "div",
        { staticClass: "login-button–primary", on: { click: _vm.handleLogin } },
        [
          _vm._v(
            _vm._s(
              _vm.loginType === "password" || _vm.loginType === "note"
                ? _vm.$t("login.login")
                : _vm.$t("login.next")
            )
          ),
        ]
      ),
      _vm.loginType === "password" || _vm.loginType === "note"
        ? _c(
            "div",
            {
              staticClass: "remember-password",
              style: {
                justifyContent:
                  _vm.loginType !== "password" ? "flex-end" : "space-between",
              },
            },
            [
              _vm.loginType === "password"
                ? _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.checked,
                        callback: function ($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("login.rememberAccount")))]
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "find-password",
                  on: { click: _vm.findPassword },
                },
                [_vm._v(_vm._s(_vm.$t("login.findPassword")))]
              ),
            ],
            1
          )
        : _c("div", { staticClass: "go-back" }, [
            _c("span", { on: { click: _vm.goBack } }, [
              _vm._v(_vm._s(_vm.$t("login.goBackLogin"))),
            ]),
          ]),
      _vm.showFaceCheck
        ? _c("faceCheck", {
            ref: "faceCheck",
            attrs: { "doctor-id": _vm.uid, "order-no": _vm.orderNo },
            on: { onClose: _vm.onClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }