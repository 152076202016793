import socketPublic from '@/socketMsg/socketPublic.js'
import inquiry from '@/socketMsg/inquiry'
import { deepClone } from '@/util/util'
import { translate as $t } from '@/lang'
import { Message } from 'element-ui'

// 处理一键更新药品用法用量
export function getDrugUsageForDis (res) {
  if (!res) {
    return
  }
  const usageli = res
  // 如果一些值为空 就不覆盖了

  if (!usageli || !usageli.doseValue || !usageli.doseUnit || !usageli.freqUnit || !usageli.usageMethod) {
    return
  }
  if (usageli.freqUnit) {
    // usageli.freqUnit = usageli.freqUnit.replace('次', '')
  }

  if (usageli.doseUnit && usageli.doseUnit == '适量') {
    usageli.doseValue = 1
  }
  if (usageli.usageMethod && usageli.usageMethod == '嚼服') {
    usageli.usageMethod = '咀嚼口服'
  }
  const currentUserData = socketPublic.state.currentUserData
  const currentUserId = socketPublic.state.currentUserId
  let drugList = null
  const activeType = socketPublic.state.activeTabName2
  if (currentUserData.clinicType) {
    if (
      activeType === $t('consultRoom.westMedicineRecipeTemp') ||
      activeType === $t('consultRoom.chineseMedicineRecipeTemp')
    ) {
      Message.warning('请先选择西药处方/中药处方,再进行重新检测后,再次更新用法用量')
      return
    }
    if (activeType === $t('consultRoom.westMedicineRecipe')) {
      drugList = deepClone(currentUserData.westernDrugList)
    } else {
      drugList = deepClone(currentUserData.chineseDrugList)
    }
  } else {
    drugList = deepClone(currentUserData.drugList)
  }
  const drugName = usageli.commDrug.indexOf('(') != -1 ? usageli.commDrug.split('(')[0] : usageli.commDrug.indexOf('（') != -1 ? usageli.commDrug.split('（')[0] : usageli.commDrug
  const index = drugList.findIndex(item => item.drugName.includes(drugName))
  if (index > -1) {
    drugList[index].dosage = usageli.doseValue
    drugList[index].dosageUnit = usageli.doseUnit
    drugList[index].usage = usageli.usageMethod
    drugList[index].frequency = ''
    drugList[index].frequencyName = usageli.freqUnit
  }
  if (currentUserData.clinicType) {
    if (activeType === $t('consultRoom.westMedicineRecipe')) {
      currentUserData.westernDrugList = drugList
    } else {
      currentUserData.chineseDrugList = drugList
    }
  } else {
    currentUserData.drugList = drugList
  }
  inquiry.commonSetCache({
    name: 'currentUserData_' + currentUserId,
    content: currentUserData
  })
}

