<template>
  <el-dialog :title="$t('login.faceCheck')"
             :visible.sync="isVisible"
             :close-on-click-modal="false"
             top="6vh"
             width="630px"
             append-to-body
             @close="isVisible = false">
    <!-- :bg-src="bgSrc" -->
    <!-- <vue-qr :size="300"
            :text="src"
            logo-src="/img/yy_logo.png" /> -->
    <div style="text-align: center;font-size: 16px;">请使用微信扫码</div>
    <el-image :src="imageUrl"
              fit="cover" />
    <!-- <canvas id="qrCode" /> -->
  </el-dialog>
</template>

<script>
// import VueQr from 'vue-qr'
import QRCode from 'qrcode' // 引入生成二维码插件

export default {
  name: 'FaceCheck',
  // components: {
  //   VueQr
  // },
  props: {
    doctorId: {
      type: String,
      default: ''
    },
    orderNo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isVisible: false,
      isLoading: false,
      src: '',
      imageUrl: ''
    }
  },
  watch: {
    isVisible (val) {
      if (!val) {
        if (this.$parent.showFaceCheck) this.$parent.showFaceCheck = false
        if (this.$parent.$parent.showFaceCheck) { this.$parent.$parent.showFaceCheck = false }
        this.$emit('onClose')
      }
    }
  },
  mounted () {
    this.src = process.env.VUE_APP_checkUrl + 'wap/index.html#/pages/h5face/index?id=' + this.doctorId + '&orderNo=' + this.orderNo + '&uType=1'// 生成的二维码为URL地址js
    const opts = {
      errorCorrectionLevel: 'H', // 容错级别
      type: 'image/png', // 生成的二维码类型
      quality: 0.3, // 二维码质量
      margin: 4, // 二维码留白边距
      width: 400, // 宽
      height: 400, // 高
      text: '人脸核身', // 二维码内容
      color: {
        dark: '#333333', // 前景色
        light: '#fff'// 背景色
      }
    }

    QRCode.toDataURL(this.src, opts, (err, url) => {
      if (err) throw err
      this.imageUrl = url
    })
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding: 10px;
  text-align: center;
  position: relative;
}
</style>
