<template>
  <div class="login-box">
    <div v-if="loginType==='password'|| loginType==='note'"
         class="login-tab"
         @click="changeLogin">
      <div :class="loginType==='password'?'is-active':''"
           class="tab-item"
           data-type="password">{{ $t('login.passwordLogin') }}</div>
      <div class="tab-line" />
      <div :class="loginType==='note'?'is-active':''"
           class="tab-item"
           data-type="note">{{ $t('login.noteLogin') }}</div>
    </div>
    <el-form ref="loginForm"
             :status-icon="false"
             :rules="loginRules"
             :model="loginForm"
             class="login-form"
             label-width="0">
      <template v-if="loginType==='password'">
        <el-form-item key="username"
                      prop="username">
          <el-input v-model.trim="loginForm.username"
                    :placeholder="$t('login.loginAccount')"
                    size="small"
                    @keyup.enter.native="handleLogin">
            <img slot="prefix"
                 src="/img/doctor_user.png">
          </el-input>
        </el-form-item>
        <el-form-item key="password"
                      prop="password">
          <el-input :type="passwordType"
                    v-model.trim="loginForm.password"
                    :placeholder="$t('login.password')"
                    size="small"
                    auto-complete="new-password"
                    @keyup.enter.native="handleLogin">
            <img slot="prefix"
                 src="/img/login_password.png">
            <img slot="suffix"
                 :src="passwordType === 'password'?'/svg/eye-open.svg':'/svg/eye.svg'"
                 class="suffix-icon-password"
                 @click.stop="showPassword('passwordType')">
          </el-input>
        </el-form-item>
      </template>
      <template v-if="loginType==='note'">
        <el-form-item key="phone"
                      prop="phone">
          <el-input v-model.trim="loginForm.phone"
                    :placeholder="$t('login.phone')"
                    size="small"
                    @keyup.enter.native="handleLogin">
            <img slot="prefix"
                 src="/img/doctor_user.png">
          </el-input>
        </el-form-item>
        <el-form-item key="code"
                      prop="code">
          <el-input v-model.trim="loginForm.code"
                    :placeholder="$t('login.code')"
                    size="small"
                    maxlength="6"
                    @keyup.enter.native="handleLogin">
            <i slot="prefix"
               class="icon-yanzhengma" />
            <template slot="suffix">
              <div class="suffix-text">
                <div :class="[{'display-line':isGetCode}]"
                     class="suffix-line" />
                <div :class="[{display:isGetCode}]"
                     class="msg-text"
                     @click="handleCheck">{{ msgText }}</div>
              </div>
            </template>
          </el-input>
        </el-form-item>
      </template>
      <template v-if="loginType==='findPass'">
        <el-form-item key="findPhone"
                      prop="findPhone">
          <el-input v-model.trim="loginForm.findPhone"
                    :placeholder="$t('login.phone')"
                    size="small"
                    @keyup.enter.native="handleLogin">
            <img slot="prefix"
                 src="/img/doctor_user.png">
          </el-input>
        </el-form-item>
        <el-form-item key="findCode"
                      prop="findCode">
          <el-input v-model.trim="loginForm.findCode"
                    :placeholder="$t('login.code')"
                    size="small"
                    maxlength="6"
                    @keyup.enter.native="handleLogin">
            <i slot="prefix"
               class="icon-yanzhengma" />
            <template slot="suffix">
              <div class="suffix-text">
                <div :class="[{'display-line':isGetCode}]"
                     class="suffix-line" />
                <div :class="[{display:isGetCode}]"
                     class="msg-text"
                     @click="handleCheck">{{ msgText }}</div>
              </div>
            </template>
          </el-input>
        </el-form-item>
      </template>
      <template v-if="loginType==='changePass'">
        <el-popover :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                    effect="dark"
                    trigger="click"
                    placement="bottom">
          <div>
            <p>1、{{ $t('login.passageValid3') }}</p>
            <p>2、{{ $t('login.passageValid4') }}</p>
            <p>3、{{ $t('login.passageValid5') }}</p>
          </div>
          <el-form-item slot="reference"
                        key="password1"
                        prop="password1">
            <el-input :type="passwordType1"
                      v-model.trim="loginForm.password1"
                      :placeholder="$t('common.pleaseInput')+$t('login.newPassword')"
                      size="small"
                      auto-complete="new-password"
                      @keyup.enter.native="handleLogin">
              <img slot="prefix"
                   src="/img/change_password.png">
              <img slot="suffix"
                   :src="passwordType1 === 'password'?'/svg/eye-open.svg':'/svg/eye.svg'"
                   class="suffix-icon-password"
                   @click.stop="showPassword('passwordType1')">
            </el-input>
          </el-form-item>
        </el-popover>
        <el-form-item key="password2"
                      prop="password2">
          <el-input :type="passwordType2"
                    v-model.trim="loginForm.password2"
                    :placeholder="$t('login.inputPassword')+$t('login.newPassword')"
                    size="small"
                    auto-complete="new-password"
                    @keyup.enter.native="handleLogin">
            <img slot="prefix"
                 src="/img/change_password.png">
            <img slot="suffix"
                 :src="passwordType2 === 'password'?'/svg/eye-open.svg':'/svg/eye.svg'"
                 class="suffix-icon-password"
                 @click.stop="showPassword('passwordType2')">
          </el-input>
        </el-form-item>
      </template>
    </el-form>
    <!-- 滑动条 -->
    <VerifySlider v-show="(loginType==='findPass'|| loginType==='note')&&(hadClick||isGetCode)"
                  ref="VerifySlider"
                  @checkSuccess="checkSuccess" />
    <div class="login-button–primary"
         @click="handleLogin">{{ loginType==='password'|| loginType==='note'?$t('login.login'):$t('login.next') }}</div>
    <div v-if="loginType==='password'|| loginType==='note'"
         :style="{justifyContent:loginType !=='password'? 'flex-end':'space-between'}"
         class="remember-password">
      <el-checkbox v-if="loginType==='password'"
                   v-model="checked">{{ $t('login.rememberAccount') }}</el-checkbox>
      <span class="find-password"
            @click="findPassword">{{ $t('login.findPassword') }}</span>
    </div>
    <div v-else
         class="go-back">
      <span @click="goBack">{{ $t('login.goBackLogin') }}</span>
    </div>
    <faceCheck v-if="showFaceCheck"
               ref="faceCheck"
               :doctor-id="uid"
               :order-no="orderNo"
               @onClose="onClose" />
  </div>
</template>

<script>
import { validatePhone, validatePassage } from '@/util/validate'
import { mapGetters } from 'vuex'
import { fetchSmsCode } from '@/api/login'
import { loginByPhone, checkFace, changePassword } from '@/api/login'
import { getStore } from '@/util/store.js'
import { securityKey } from '@/config/env'
import { getGibberishAES, getAesDecode } from '@/util/gibberish-aes.js'
import inquiry from '@/socketMsg/inquiry.js'
import VerifySlider from '@/components/VerifySlider'
import faceCheck from './faceCheck'

const validatorPhone = (rule, value, callback) => {
  if (validatePhone(value)[0]) {
    callback(new Error(validatePhone(value)[1]))
  } else {
    callback()
  }
}

const validatorPassage = (rule, value, callback) => {
  if (validatePassage(value)[0]) {
    callback(new Error(validatePassage(value)[1]))
  } else {
    callback()
  }
}

const defaultTime = 60
export default {
  name: 'Userlogin',
  components: {
    VerifySlider,
    faceCheck
  },
  data () {
    const validatorTwoPassage = (rule, value, callback) => {
      if (this.loginForm.password1 !== value) {
        callback(new Error(this.$t('login.passageValid2')))
      } else if (!value) {
        callback(new Error(this.$t('login.passageValid2')))
      } else {
        callback()
      }
    }
    return {
      doctorId: '',
      showTooltip: false,
      sendSuccess: this.$t('login.sendSuccTip'),
      msgTime: defaultTime,
      msgText: this.$t('login.sendCode'),
      isGetCode: false,
      showFaceCheck: false,
      loginForm: {
        username: '',
        password: '',
        phone: '',
        code: ''
      },
      checked: false,
      isLoading: false,
      passwordType: 'password',
      passwordType1: 'password',
      passwordType2: 'password',
      loginRules: {
        username: [
          {
            required: true,
            message:
              this.$t('common.pleaseInput') + this.$t('login.loginAccount'),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('common.pleaseInput') + this.$t('login.password'),
            trigger: 'blur'
          },
          {
            min: 6,
            message: this.$t('login.passwordTip'),
            trigger: 'blur'
          }
        ],
        phone: [{ required: true, trigger: 'blur', validator: validatorPhone }],
        code: [
          {
            required: true,
            trigger: 'blur',
            min: 6,
            message: this.$t('login.codeTip')
          }
        ],
        findPhone: [{ required: true, trigger: 'blur', validator: validatorPhone }],
        findCode: [
          {
            required: true,
            trigger: 'blur',
            min: 6,
            message: this.$t('login.codeTip')
          }
        ],
        password1: [
          { required: true, trigger: 'blur', validator: validatorPassage }
        ],
        password2: [
          {
            required: true,
            trigger: 'blur',
            validator: validatorTwoPassage
          }
        ]
      },
      loginType: 'password',
      isCheckSuccess: false,
      showDrag: false,
      hadClick: false,
      timer: null,
      loginData: {},
      checkTimer: null,
      uid: '',
      orderNo: ''
    }
  },
  computed: {
    ...mapGetters(['tagHome'])
  },
  mounted () {
    const saveUserInfo = this.$cookies.get('saveUserInfo_doctor')
    if (saveUserInfo) {
      this.loginForm.username = getAesDecode(saveUserInfo.username, securityKey)
      this.loginForm.password = getAesDecode(saveUserInfo.password, securityKey)
    }
  },
  methods: {
    onClose () {
      clearInterval(this.checkTimer)
    },
    handleCheck () {
      this.hadClick = true
      // this.showDrag = true
      this.$refs.VerifySlider.reset()
    },
    checkSuccess () {
      this.isCheckSuccess = true
      this.handleSend()
    },
    resetData () {
      this.loginForm = {}
      this.hadClick = false
      this.msgTime = defaultTime
      this.msgText = this.$t('login.sendCode')
      this.isGetCode = false
      clearInterval(this.timer)
    },
    goBack () {
      this.resetData()
      this.loginType = 'password'
      this.$emit('changeType', 1)
    },
    findPassword () {
      this.resetData()
      this.loginType = 'findPass'
      this.$emit('changeType', 0)
    },
    showPassword (key) {
      this[key] == '' ? (this[key] = 'password') : (this[key] = '')
    },
    async handleLogin () {
      try {
        const valid = await this.$refs.loginForm.validate()
        this.isLoading = true
        if (valid) {
          if (this.loginType === 'password') {
            const res = await this.$store.dispatch('LoginByUsername', this.loginForm)
            this.loginData = res
            if (this.checked) {
              const username = getGibberishAES(this.loginForm.username, securityKey)
              const password = getGibberishAES(this.loginForm.password, securityKey)
              this.$cookies.set('saveUserInfo_doctor', { username: username, password: password }, -1)
            }
            // res.login_check = 1
            if (res.login_check == '1') {
              this.uid = res.uid
              this.orderNo = res.uid + new Date().getTime()
              this.showFaceCheck = true
              this.$nextTick(() => {
                this.$refs.faceCheck.isVisible = true
              })
              this.checkTimer = setInterval(this.fetchFaceCheck, 3000)
            } else {
              this.changeState()
            }
          } else if (this.loginType === 'note') {
            const res = await this.$store.dispatch('LoginByPhone', this.loginForm)
            this.loginData = res
            if (res.login_check == '1') {
              this.uid = res.uid
              this.orderNo = res.uid + new Date().getTime()
              this.showFaceCheck = true
              this.$nextTick(() => {
                this.$refs.faceCheck.isVisible = true
              })
              this.checkTimer = setInterval(this.fetchFaceCheck, 3000)
            } else {
              this.changeState()
            }
          } else if (this.loginType === 'findPass') {
            const res = await loginByPhone(
              this.loginForm.findPhone,
              this.loginForm.findCode
            )
            this.doctorId = res.data.data.uid
            this.loginType = 'changePass'
          } else if (this.loginType === 'changePass') {
            await changePassword({
              uid: this.doctorId,
              password: getGibberishAES(this.loginForm.password1, securityKey),
              rpassword: getGibberishAES(this.loginForm.password2, securityKey)
            })
            this.$message.success(this.$t('login.changeSuccTip'))
            this.loginType = 'password'
            this.$emit('changeType', 1)
          }
        }
        inquiry.clearCache()
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        console.error(err)
      }
    },
    changeState () {
      this.$store.commit('SET_TOP_MENU_INDEX', 0)
      this.$store.commit('SET_ACCESS_TOKEN', this.loginData.access_token)
      this.$store.commit('SET_REFRESH_TOKEN', this.loginData.refresh_token)
      this.$store.commit('SET_EXPIRES_IN', this.loginData.expires_in)
      this.$router.push({
        path: '/',
        query: { isLogin: true }
      })
    },
    // 人脸核验验证是否通过
    async fetchFaceCheck () {
      try {
        const res = await checkFace({ orderNo: this.orderNo, uid: this.uid })
        if (res.data.code == 0) {
          clearInterval(this.checkTimer)
          this.showFaceCheck = false
          this.changeState()
        }
      } catch (err) {
        console.error(err)
      }
    },
    changeLogin (e) {
      // if (e.target.dataset.type !== 'note') {
      //   this.showDrag = false
      // } else {
      //   this.showDrag = true
      // }

      this.loginType = e.target.dataset.type
        ? e.target.dataset.type
        : this.loginType
    },
    async handleSend () {
      try {
        if (this.isGetCode) return
        if (validatePhone(this.loginType == 'findPass' ? this.loginForm.findPhone : this.loginForm.phone)[0]) {
          this.$message.error('请输入正确的手机号')
          return
        }
        this.isGetCode = true
        const res = await fetchSmsCode({
          phone: this.loginType == 'findPass' ? this.loginForm.findPhone : this.loginForm.phone
        })
        this.isGetCode = false
        if (res.data.code == '0') {
          this.$message.success('验证码发送成功')
          this.msgText = this.sendSuccess.replace('${time}', this.msgTime)
          this.isGetCode = true
          this.timer = setInterval(() => {
            this.msgTime--
            this.msgText = this.sendSuccess.replace('${time}', this.msgTime)
            if (this.msgTime == 0) {
              this.msgTime = defaultTime
              this.msgText = this.$t('login.sendCode')
              this.isGetCode = false
              clearInterval(this.timer)
            }
          }, 1000)
        } else {
          this.$message.error(res.data.msg)
        }
      } catch (err) {
        this.isGetCode = false
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item {
  margin-top: 50px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

/deep/ .el-form-item:hover {
  border: 1px solid #3b94ff;
}

.login-box {
  .login-tab {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    color: #999;
    margin: 40px 80px;
    .tab-item {
      cursor: pointer;
    }
    .tab-line {
      width: 1px;
      background-color: #d8d8d8;
      height: 18px;
    }
    .is-active {
      color: #3b94ff;
    }
  }
  .login-form {
    margin: 10px 0;

    i {
      color: #999;
    }

    /deep/ .el-form-item__content {
      width: 100%;
    }

    /deep/ .el-form-item {
      margin-bottom: 12px;
    }

    /deep/ .el-input {
      input {
        text-indent: 5px;
        border: none;
        border-radius: 0;
        background: transparent;
        color: #333;
      }

      i {
        font-size: 16px !important;
        cursor: pointer;
      }
    }

    /deep/ .el-input__prefix {
      left: 10px;

      img {
        vertical-align: -3px;
      }
    }

    /deep/ .el-form-item__error {
      color: #e20000;
      font-size: 14px;
      height: 50px;
      line-height: 50px;
      padding-top: 0;
    }

    /deep/ .el-input--small .el-input__inner {
      height: auto;
      line-height: inherit;
    }
    .suffix-icon-password {
      width: 16px;
      cursor: pointer;
      margin-right: 6px;
      vertical-align: -3px;
    }
  }

  .login-button–primary {
    margin-top: 50px;
    color: #fff;
    cursor: pointer;
    height: 44px;
    text-align: center;
    font-size: 16px;
    line-height: 44px;
    background: #3b94ff;
    border-radius: 4px;
    background-size: 100% 100%;
  }

  .login-button–primary:hover {
    background: #32b4f9;
  }

  .remember-password {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .find-password {
      color: #3b94ff;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .go-back {
    margin-top: 20px;
    text-align: center;
    span {
      color: #3b94ff;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .suffix-text {
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
    color: #3b94ff;
    display: flex;
    align-items: center;
    .suffix-line {
      margin-right: 14px;
      width: 1px;
      height: 18px;
      background-color: #3b94ff;
      vertical-align: middle;
      &.display-line {
        background-color: #ccc;
      }
    }
    .msg-text {
      width: 80px;
      &.display {
        color: #ccc;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .login-box {
    .login-tab {
      margin: 20px 40px;
    }
    .login-button–primary {
      margin-top: 30px;
    }
    .el-form-item {
      margin-top: 30px;
    }
    .drag {
      margin-top: 30px;
    }
  }
}
</style>
