var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "drug-use-assistant animate__animated animate__fadeOutRight",
    },
    [
      _c(
        "div",
        { staticClass: "assistant-title" },
        [
          _c("el-image", {
            staticStyle: { margin: "0 10px" },
            attrs: { src: "/img/yy_logo.png", fit: "cover" },
          }),
          _c("span", [_vm._v("银叶合理用药助手")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "assistant-content " },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "用药检测", name: "first" } },
                [
                  _c("drugTest", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.checkSuccess,
                        expression: "checkSuccess",
                      },
                    ],
                    ref: "drugTestRef",
                  }),
                  _c("testFail", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.checkSuccess,
                        expression: "!checkSuccess",
                      },
                    ],
                    ref: "testFailRef",
                    on: { checkDrugs: _vm.checkDrugs },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  staticStyle: { height: "100%" },
                  attrs: { label: "处方用药", name: "second" },
                },
                [_c("drugInfo", { ref: "drugInfo" })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }