<template>
  <div class="login-container">
    <div class="top-box">
      <div class="logo-box"
           @click.stop="jumpInitialPage">
        <el-image fit="cover"
                  src="/img/logo.png" />
      </div>
    </div>
    <div class="login-weaper">
      <div class="right-box">
        <div class="login-title">
          {{ type ? $t('login.doctorLogin') : $t('login.findPassword') }}
        </div>
        <div class="login-main">
          <userLogin @changeType="changeType" />
        </div>
      </div>
    </div>
    <div class="login-page-footer">
      <div class="foot-box">
        <span>{{ `ICP${$t('login.putRecordsNum')}：` }}
          <a href="https://beian.miit.gov.cn"
             target="_blank">{{
            $t('login.currentRecord')
          }}</a></span>
      </div>
    </div>
  </div>
</template>
<script>
import userLogin from './userlogin'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  components: {
    userLogin
  },
  data () {
    return {
      time: '',
      activeName: this.$route.meta.activeName,
      thirdPartyForm: {},
      showCooperation: false,
      type: 1
    }
  },
  computed: {
    ...mapGetters(['website', 'tagHome'])
  },
  methods: {
    jumpInitialPage () {
      if (process.env.NODE_ENV == 'development') {
        // 开发环境
        window.open('https://test-www.ynye.com', '_self')
      } else if (process.env.NODE_ENV == 'production') {
        // 生产环境
        const url =
          window.location.origin.indexOf('test') > -1
            ? 'https://test-www.ynye.com'
            : 'https://www.ynye.com'
        window.open(url, '_self')
      } else {
        // 本地环境
      }
    },
    applyCooperation () {
      this.showCooperation = true
      this.$nextTick(() => {
        this.$refs.cooperation.isVisible = true
      })
    },
    changeType (type) {
      this.type = type
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-card__body {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.login-container {
  height: 100%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .login-page-footer {
    height: 40px !important;
  }
  .login-weaper {
    .right-box {
      width: 480px !important;
      .login-title {
        margin-top: 20px !important;
      }
      .login-main {
        padding: 0 45px !important;
      }
    }
  }
}

.login-container {
  width: 100%;
  margin: 0 auto;
  background-color: #e7ecf9;
  display: flex;
  flex-direction: column;

  .top-box {
    height: 70px;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo-box {
      margin-left: 13.85%;
      cursor: pointer;
      .el-image {
        // height: 56px;
      }
    }
  }

  .login-weaper {
    width: 100%;
    flex: 1;
    background-image: url('/img/login-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 2%;
    position: relative;

    .right-box {
      width: 580px;
      height: 90%;
      background: #ffffff;
      margin: 0 auto;
      border-radius: 10px;
      overflow: hidden;
      overflow-y: auto;
      position: absolute;
      right: 12vw;
      background-color: rgba(255, 255, 255, 0.9);
      display: flex;
      flex-direction: column;

      .empty {
        height: 10%;
      }

      .login-title {
        margin-top: 90px;
        font-size: 24px;
        text-align: center;
        font-weight: 700;
      }

      .login-main {
        flex: 1;
        // margin: 0 auto;
        padding: 0 90px;
        box-sizing: border-box;
      }
    }
  }

  .el-card.is-always-shadow {
    box-shadow: none;
  }

  .login-page-footer {
    width: 100%;
    background-color: #f3f4f6;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: #3b94ff;

      &:hover {
        color: #f00;
        text-decoration: underline;
      }
    }
  }
}
</style>
